var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","app":""}},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('page-title',{attrs:{"title":"Digital Ecosystem Sub-Activities","useBackBtn":true}})],1),_c('div',{staticClass:"mt-5 col-md-12"},[_c('v-row',[_c('v-card',{staticClass:"col-md-12"},[_c('div',{staticClass:"mt-1 d-flex col justify-end align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"title":"","right":"","fab":"","small":"","color":"success white--text"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Indicators")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+"% ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{key:item.name,attrs:{"offset-y":"","app":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":"","color":"success"},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-summit")]),_vm._v(" Set Baseline")],1)],1),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-numeric-positive-1")]),_vm._v(" Report Implementations")],1)],1),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-timeline-plus-outline")]),_vm._v(" Set Plan")],1)],1),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-compass")]),_vm._v(" View Progress")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete")],1)],1)],1)],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }