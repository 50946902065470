<template>
  <v-container fluid app>
    <div class="col-md-12 mb-3">
      <page-title
        title="Digital Ecosystem Sub-Activities"
        :useBackBtn="true"
      ></page-title>
    </div>

    
    <div class="mt-5 col-md-12">
      <v-row>
        <v-card  class="col-md-12">
          <div class="mt-1 d-flex col justify-end align-center">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  small
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Indicators</span>
            </v-tooltip>
          </div>

          <v-data-table :headers="headers" :items="desserts">
            <template v-slot:item.calories="{ item }">
              <v-chip :color="getColor(item.calories)" dark>
                {{ item.calories }}%
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu :key="item.name" offset-y app>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    icon
                    color="success"
                    ><v-icon>mdi-dots-vertical</v-icon></v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-summit</v-icon
                      >
                      Set Baseline</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-numeric-positive-1</v-icon
                      >
                      Report Implementations</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-timeline-plus-outline</v-icon
                      >
                      Set Plan</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-compass</v-icon
                      >
                      View Progress</v-list-item-title
                    >
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item link to="">
                    <v-list-item-title
                      ><v-icon small
                        >mdi-delete</v-icon
                      >
                      Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// import PageTitle from "../../../digitaltp_shared/page-title.vue";
export default {
  components: {
    // PageTitle,
  },
  name: "ProjectSubActivity",
  data() {
    return {
      show: false,
      headers: [
        {
          text: "Indicator",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Baseline", value: "fat" },
        { text: "Actions", value: "actions" },
      ],
      desserts: [
        {
          name: "Statistical Information Management System",
          calories: 54,
          fat: "20",
          carbs: "$ 6.7 Mill",
          protein: "$ 4.3 Mill",
          expenditure: "$ 2.4 Mill",
          assigned: "NYARAKA",
          actions: "1%",
        },
        {
          name: "e-Commerce",
          calories: 100,
          fat: "34%",
          carbs: "5.6",
          protein: "$ 4.3 Mill",
          expenditure: "$ 1.3 Mill",
          assigned:
            "MAWASILIANO",

          actions: "8%",
        },
        {
          name: "ICT Regulatory",
          calories: 26,
          fat: "23%",
          carbs: "$ 4.8 Mill",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          assigned: "eGA",
          actions: "16%",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  methods: {
    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },
  },
};
</script>